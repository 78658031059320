export const getFeatureflags = () => {
  return {
    allowWebLogin: false,
    applyNonUsNoticeEnabled: false,
    applyShowStepNavigationOnTop: true,
    applyShowTermPaymentEstimate: false,
    applyStatementWebUploadEnabled: false,
    applyTriggerOcrolusRunAfterPlaidReport: false,
    includeBankStatementsScreen: true,
    personalLoanWithEvenFinancial: false,
    plaidConnect: true,
    termsAndDocuSign: false,
    withApplyContainerAnimation: true,
  };
};
