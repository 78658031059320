import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { PlaidLink } from 'react-plaid-link';
import { Link } from 'react-router-dom';
import { ModalManager, Effect } from 'react-dynamic-modal';
import { BasicModal, AccountBody } from '@components/_shared/Modals/Modals';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { ELEMENTS_GAP } from '@utils/spacing';
import { cn } from '@utils/cn';
import { Helmet } from 'react-helmet';
import { Spinner } from '@components/Spinner/Spinner';
import { plaidConnect } from '../../../api/plaidConnect';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { plaidLinkToken } from '../../../api/plaidLinkToken';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Loader, Stack, StepCard, useResponsiveBreakpoints } from 'shared-components';
import { SkipConnectCard } from './SkipConnectCard';

const PlaidConnectCard = ({
  showProcessingModal,
  loan_id,
  loan_amount,
  first,
  setTemp,
  business_id,
  customConnectEndpoint,
  customLinkTokenEndpoint,
  customOnConnect,
  match,
  dataLayerPush,
  nextRoute,
  statementsType,
  loanType,
  textConnectWithPlaidTextBody = 'Securely connect your bank account with our trusted partner Plaid.',
}) => {
  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.length > 0
      ? loanType[0].toString().toUpperCase()
      : 'WC'
    : loanType?.toString().toUpperCase() ?? 'WC';
  const responsiveBreakpoints = useResponsiveBreakpoints();
  const history = useHistory();
  const [{ data: plaidConnectResponse, loading: plaidConnectLoading, error: plaidConnectError }, triggerPlaidConnect] =
    plaidConnect();
  const [
    { data: plaidLinkTokenResponse, loading: plaidLinkTokenLoading, error: plaidLinkTokenError },
    generatePlaidLinkToken,
  ] = plaidLinkToken();

  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [metadata, setMetadata] = useState();
  const isPlaidAlreadyConnected = statementsType === 'plaid';

  useEffect(() => {
    if (plaidConnectResponse) {
      setTemp({ haveStatements: true });
      dispatch(change('application', 'have_statements', true));
      dispatch(change('application', 'type_of_statements', 'plaid'));

      ModalManager.close();

      if (customOnConnect) {
        customOnConnect(plaidConnectResponse);
      } else {
        nextRoute();
      }
    }
  }, [plaidConnectResponse]);

  useEffect(() => {
    if (plaidConnectError) {
      ModalManager.close();
    }
  }, [plaidConnectError]);

  const connectBank = async (metadata, mainAccountId, publicToken) => {
    showProcessingModal(
      'We are processing your application',
      'This step can take several minutes, please do not click back on your internet browser or refresh this page.'
    );
    console.log('[PLAID] connectBank', metadata, mainAccountId, publicToken);

    triggerPlaidConnect({
      data: {
        loan_id: loan_id,
        loan_type: loanTypeSafe,
        amount: loan_amount,
        first: first,
        bank_accounts: metadata,
        main_account_id: mainAccountId,
        public_token: publicToken,
      },
      ...(customConnectEndpoint ? { url: customConnectEndpoint } : {}),
    });
  };

  useEffect(() => {
    generatePlaidLinkToken({
      data: {
        accountId: business_id,
      },
      ...(customLinkTokenEndpoint ? { url: customLinkTokenEndpoint } : {}),
    });
  }, []);

  useEffect(() => {
    if (plaidLinkTokenError) {
      console.error('Error generating plaid link token', plaidLinkTokenError);
    }
  }, [plaidLinkTokenError]);

  useEffect(() => {
    if (plaidLinkTokenResponse && plaidLinkTokenResponse.link_token) {
      setToken(plaidLinkTokenResponse.link_token);
    }
  }, [plaidLinkTokenResponse]);

  const handleExit = (error, metadata) => {
    console.log('[PLAID] handleExit', error, metadata);
    if (error !== null) {
      setError('Error connecting to bank account. Please try again or choose to upload statements manually.');
    }
    if (metadata?.accounts && metadata.accounts.length === 0) {
      setError('No bank accounts connected. Please make sure to give access to at least one checking account.');
    }
  };

  const handleEvent = (event, metadata) => {
    console.log('[PLAID] handleEvent', event, metadata);
    if (event === 'ERROR' || event === 'error') {
      setError('Error connecting to bank account. Please try again.');
    }
  };

  const openAccountSelection = (accounts, metadata, token) => {
    const title = 'Please select your primary business checking account:';
    const body = (
      <AccountBody
        accounts={accounts}
        itemClick={(mainAccountId) => {
          ModalManager.close();
          connectBank(metadata, mainAccountId, token);
        }}
      />
    );
    ModalManager.open(
      <BasicModal effect={Effect.RotateFromBottom3D} title={title} body={body} onRequestClose={() => false} />
    );
  };

  // For testing purposes:
  // openAccountSelection([
  //   {
  //     id: '1',
  //     name: 'Test Account',
  //     mask: '1234',
  //   },
  //   {
  //     id: '2',
  //     name: 'Test Account 2',
  //     mask: '5678',
  //   },
  // ]);
  const handleSuccess = (token, metadata) => {
    setMetadata(metadata);
    setToken(token);
    console.log('[PLAID] handleSuccess', token, metadata);
    if (metadata.accounts) {
      console.log('[PLAID] found accounts', metadata.accounts);
      let checkingAccounts = metadata.accounts.filter((account) => {
        return account.subtype === 'checking';
      });

      if (checkingAccounts.length === 0) {
        console.log('[PLAID] no checking accounts ', checkingAccounts);
        setError('No checking accounts found. Please connect bank account with a checking account.');
      } else {
        console.log('[PLAID] checking accounts are:', checkingAccounts);
        if (checkingAccounts.length === 1) {
          connectBank(metadata, checkingAccounts[0].id, token);
        } else {
          openAccountSelection(checkingAccounts, metadata, token);
        }
      }
    } else {
      console.error('[PLAID] No accounts found. ', metadata.accounts);
      setError('No accounts found. Please make sure to give access to at least one checking account.');
    }
  };
  if (!token && !plaidLinkTokenError) {
    return (
      <StepCard
        type="current"
        headline={undefined}
        textBody={<Loader />}
        cardProps={{ className: 'w-full w-[280px] justify-between' }}
        buttonProps={{
          children: '',
        }}
        button={undefined}
      />
    );
  }
  return (
    <>
      {isPlaidAlreadyConnected && (
        <StepCard
          type="completed"
          headline="Connected with Plaid"
          textBody="Securely connect your bank account with our trusted partner Plaid."
          button="Continue"
          buttonProps={{
            children: 'Continue',
            onClick: () => {
              if (customOnConnect) {
                customOnConnect(plaidConnectResponse);
              } else {
                nextRoute();
              }
            },
          }}
          cardProps={{
            className: 'justify-between',
          }}
        />
      )}
      {token && !isPlaidAlreadyConnected && (
        <StepCard
          type="upcomming"
          headline="Connect with Plaid"
          textBody={
            <>
              {textConnectWithPlaidTextBody}
              {(plaidConnectError || plaidLinkTokenError || plaidLinkTokenError || error) && (
                <Stack gap={ELEMENTS_GAP}>
                  {plaidConnectError && (
                    <Alert severity="error">
                      <AlertTitle>Failed to connect</AlertTitle>
                      <DataError msg="Unfortunately, connecting bank account failed." error={plaidConnectError} />
                    </Alert>
                  )}
                  {error && <div style={{ color: '#ed4337' }}>{error}</div>}
                  <div className="plaid-button-wrap">
                    {plaidLinkTokenError && (
                      <Alert severity="error">
                        <AlertTitle>Connect disabled</AlertTitle>
                        <DataError
                          msg="Unfortunately, connecting bank account is now disabled. Please choose to upload statements manually. Sorry for the inconvenience."
                          error={plaidLinkTokenError}
                        />
                      </Alert>
                    )}
                  </div>
                </Stack>
              )}
            </>
          }
          additionalComponent={
            <>
              <PlaidLink
                token={token}
                onExit={handleExit}
                onEvent={handleEvent}
                onSuccess={handleSuccess}
                className="!text-charcoal !text-center !uppercase !bg-dawn !font-condensed !text-[18px] !px-[20px] !leading-normal !h-[50px] !btn-base !bg-spark !text-charcoal !hover:bg-sparkHover !border-charcoal !rounded-[100px] !border !btn-primary-green"
              >
                Connect
              </PlaidLink>
              {responsiveBreakpoints.isMobile && (
                <SkipConnectCard
                  asText
                  match={match}
                  loan_id={loan_id}
                  first={first}
                  dataLayerPush={dataLayerPush}
                  history={history}
                />
              )}
            </>
          }
          cardProps={{ className: 'w-full justify-between' }}
        />
      )}
    </>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    business_id: selector(state, 'business_id'),
    contact_id: selector(state, 'owner_1_id'),
    mobile: selector(state, 'owner_1_mobile'),
    email: selector(state, 'owner_1_email'),
    loanType: selector(state, 'loan_type'),
    statementsType: selector(state, 'type_of_statements'),
    theme: state.theme,
  };
};

export const PlaidConnectCardContainer = connect(mapStateToProps)(PlaidConnectCard);
